export const colors = [
  {
    description: "Fehér (RAL 9016)",
    fileName: "white.jpg",
    rgb: "rgb(246, 246, 246)",
    border: true,
  },
  {
    description: "Barna (RAL 8019)",
    fileName: "brown.jpg",
    rgb: "rgb(64, 58, 58)",
  },
  {
    description: "Sötét barna (RAL 8022)",
    fileName: "black-brown.jpg",
    rgb: "rgb(33, 33, 33)",
  },
  {
    description: "Fekete (RAL 9005)",
    fileName: "black.jpg",
    rgb: "rgb(10, 10, 10)",
  },
  {
    description: "Grafit (RAL 7024)",
    fileName: "graphite.jpg",
    rgb: "rgb(71, 74, 81)",
  },
  {
    description: "Antracit (RAL 7016)",
    fileName: "antracite.jpg",
    rgb: "rgb(41, 49, 51)",
  },
  {
    description: "Ezüst (F1)",
    fileName: "silver.jpg",
    rgb: "rgb(165, 165, 165)",
  },
  {
    description: "Arany (F3)",
    fileName: "gold.jpg",
    rgb: "rgb(214, 174, 1)",
  },
  {
    description: "Bronz (F4)",
    fileName: "bronze.jpg",
    rgb: "rgb(112, 83, 53)",
  },
  {
    description: "Titánium (F9)",
    fileName: "titanium.jpg",
    rgb: "rgb(143, 143, 143)",
  },
];
