import ProductContainer from "./product-container";

function ProductCategory({ category, producerName, first }) {
  return (
    <div>
      <h1 className='product__category__name'>{category.title}</h1>
      {category.items.map((product, index) => {
        return (
          <ProductContainer
            key={index}
            product={product}
            expanded={first && index === 0}
            producerName={producerName}
          />
        );
      })}
    </div>
  );
}

export default ProductCategory;
