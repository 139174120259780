import { Link } from "react-router-dom";

const producers = [
  {
    title: "Astex",
    link: "astex",
    pictureExtraClass: "producer__astex",
  },
  {
    title: "Simonswerk",
    link: "simonswerk",
  },
  {
    title: "Medos",
    link: "medos",
  },
  {
    title: "Axor",
    link: "axor",
  },
  {
    title: "Konig",
    link: "konig",
    logoExtraClass: "producer__logo__konig",
  },
];

function Producers() {
  return (
    <div className='producer'>
      <h2 className='producer__title'>Forgalmazott Márkák</h2>
      <div className='producer__container'>
        {producers.map((producer, index) => {
          const { title, link, pictureExtraClass, logoExtraClass } = producer;

          const pictureClass =
            "producer__main" +
            (pictureExtraClass ? " " + pictureExtraClass : "");
          const logoClass =
            "producer__logo" + (logoExtraClass ? " " + logoExtraClass : "");

          return (
            <div key={index} className='producer__item'>
              <h3 className='producer__name'>{title}</h3>
              <img
                className={pictureClass}
                src={`/images/producers/${link}_producer.jpg`}
                alt=''
              />
              <Link className='producer__anchor' to={"producer/" + link}>
                <img
                  className={logoClass}
                  src={`/images/producers/${link}_logo.svg`}
                  alt=''
                />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Producers;
