import logo from "../images/logo-hu.webp";

function Header() {
  return (
    <a href='/'>
      <img className='nav__brand' src={logo} alt='' />
    </a>
  );
}

export default Header;
