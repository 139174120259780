export const products = [
  {
    producerName: "astex",
    producerLink: "https://astex-t.com/european",
    producerDescription:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. ",
    categories: [
      {
        title: "Ablak kilincsek",
        items: [
          {
            name: "ANTE_WH_003",
            title: "ANTEY WH 003",
            colors: 6,
            features: [
              {
                name: "Cikk",
                value: "ANTEY WH 003",
              },
              {
                name: "Tengely hossz",
                value: "35, 37, 40, 43 mm",
              },
              {
                name: "Rögzítés",
                value: "45º/90º",
              },
              {
                name: "Csavarok М5х40, М5х45, М5х50",
                value: "2 darab",
              },
              {
                name: "Csomagolás",
                value: "100 darab",
              },
            ],
            descriptions: [
              "Az ANTEY WH 003 ajtó kilincs anyaga aluminium ötvözet.",
              "A kilincs háza és a takarósapkája műanyagból készül.",
              "A termékek UV védelemmel ellátottak.",
            ],
          },
          {
            name: "KRONOS_WH_001",
            title: "KRONOS WH 001",
            colors: 6,
            features: [
              {
                name: "Cikk",
                value: "KRONOS WH 001",
              },
              {
                name: "Tengely hossz",
                value: "35, 37, 40, 43 mm",
              },
              {
                name: "Rögzítés",
                value: "45º/90º",
              },
              {
                name: "Csavarok М5х40, М5х45, М5х50",
                value: "2 darab",
              },
              {
                name: "Csomagolás",
                value: "100 darab",
              },
            ],
            descriptions: [
              "A KRONOS WH 001 ablak kilincs anyaga aluminium ötvözet",
              "A kilincs háza és a takarósapkája műanyagból készül.",
              "A termékek UV védelemmel ellátottak.",
            ],
          },
          {
            name: "KRONOS_WH_018_019",
            title: "KRONOS WH 018/019",
            colors: 6,
            features: [
              {
                name: "Cikk",
                value: "KRONOS WH 018/019",
              },
              {
                name: "Zár típus",
                value: "Push / Swing",
              },
              {
                name: "Opening position",
                value: "45º/90º",
              },
              {
                name: "Tengely hossz (A)",
                value: "35, 37, 40, 43 mm",
              },
              {
                name: "Csavarok М5х40, М5х45, М5х50",
                value: "2 darab",
              },
              {
                name: "Csomagolás",
                value: "80 darab",
              },
            ],
            descriptions: [
              "A KRONOS WH 018/019 ablak kilincs anyaga aluminium ötvözet",
              "A kilincs háza és a takarósapkája műanyagból készül.",
              "A termékek UV védelemmel ellátottak.",
            ],
          },
        ],
      },
      {
        title: "Ajtó kilincsek",
        items: [
          {
            name: "DHS_ANTEY_92_32_16",
            title: "DHS ANTEY 92/32/16",
            colors: 10,
            features: [
              {
                name: "Cikk",
                value: "DHS ANTEY 92/32/16",
              },
              {
                name: "Ajtó vastagsága",
                value: "58-90 mm",
              },
              {
                name: "Axel spacing",
                value: "92 mm",
              },
              {
                name: "Plate width",
                value: "32 mm",
              },
              {
                name: "Csavarok М5х100",
                value: "3 darab",
              },
              {
                name: "Tengely hossz",
                value: "130 mm",
              },
              {
                name: "Center distance between screws",
                value: "216 mm",
              },
              {
                name: "Csomagolás",
                value: "80 darab",
              },
            ],
            descriptions: [
              "A DHS ANTEY 92/32/16 ajtó kilincs anyaga aluminium ötvözet",
              "A termékek UV védelemmel ellátottak.",
            ],
          },
        ],
      },
      {
        title: "Ablak pántok",
        items: [
          {
            name: "AT_27",
            title: "АТ - 27",
            colors: 10,
            features: [
              {
                name: "Cikk",
                value: "AT 27",
              },
              {
                name: "Ajtó súlya",
                value: "140 kg",
              },
              {
                name: "Spacing between door leaf & frame surface",
                value: "15.0 - 23.5 mm",
              },
              {
                name: "Csomagolás",
                value: "különálló darab, 30 darabos csomag",
              },
              {
                name: "Fasteners (self-tapping screws)",
                value: "6х40 mm. - 4 db., 6x50 mm. - 2 db.",
              },
            ],
            descriptions: [
              "Made of zinc alloy",
              "Bushings made of glass fiber reinforced polyamide",
              "Fixing screws -  PZ-2",
              "Closed position adjustment",
            ],
          },
          {
            name: "DHV",
            title: "DHV",
            colors: 10,
            features: [
              {
                name: "Cikk",
                value: "DHV",
              },
              {
                name: "Ajtó súlya",
                value: "140 kg",
              },
              {
                name: "Spacing between door leaf & frame surface",
                value: "15.0 - 20.0 mm",
              },
              {
                name: "Csomagolás",
                value: "különálló darab, 30 darabos csomag",
              },
              {
                name: "Fasteners (self-tapping screws)",
                value: "6х40 mm. - 4 db., 6x50 mm. - 2 db.",
              },
            ],
            descriptions: [
              "Made of extruded aluminium",
              "Bushings made of glass fiber reinforced polyamide",
              "Fixing screws -  PZ-2",
              "Closed position adjustment",
            ],
          },
          {
            name: "IDEAL",
            title: "IDEAL",
            colors: 10,
            features: [
              {
                name: "Cikk",
                value: "IDEAL",
              },
              {
                name: "Ajtó súlya",
                value: "120 kg",
              },
              {
                name: "Spacing between door leaf & frame surface",
                value: "15.0 - 23.5 mm",
              },
              {
                name: "Csomagolás",
                value: "különálló darab, 30 darabos csomag",
              },
              {
                name: "Fasteners (self-tapping screws)",
                value: "6х40 mm. 6 db.",
              },
            ],
            descriptions: [
              "Made of zinc alloy",
              "Bushings made of glass fiber reinforced polyamide",
              "Fixing screws -  PZ-2",
              "Closed position adjustment",
            ],
          },
        ],
      },
    ],
  },
];
