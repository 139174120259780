import heroPict from "../images/hero_hd4.jpg";
import heroPictMobile from "../images/hero_hd4_mobile.jpg";
import { useNavigate } from "react-router-dom";

function Hero() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/details");
  };

  return (
    <div className='hero'>
      <picture>
        <source media='(min-width:768px)' srcSet={heroPict} />
        <img
          src={heroPictMobile}
          alt='Door with a key'
          className='hero__picture'
        />
      </picture>
      <div className='hero__text'>
        <div className='hero__text__background'>
          <h2 className='hero__caption'>
            Cpp-Trade Bt: nyílászárók és vasalatok
          </h2>
          <p className='hero__pitch'>
            2000-ben alakultunk. Műanyag nyílászárók, ajtók és ablakok vasalat
            elemeinek exportjával és importjával foglalkozunk.
          </p>
        </div>
        <button onClick={handleClick} className='hero__details'>
          Részletek
        </button>
      </div>
    </div>
  );
}

export default Hero;
