function DataGdpr() {
  return (
    <div className='data__gdpr'>
      <h2>ADATVÉDELMI SZABÁLYZAT ÉS TÁJÉKOZTATÓ</h2>
      <ol className='data__gdpr--topics'>
        <li>
          Általános rendelkezések
          <div className='data__gdpr--topic'>
            <p>
              <strong>(1)</strong> A <strong>CPP-TRADE Bt.</strong>, mint
              Adatkezelő az általa üzemeltetett http://www.cpptrade.hu weboldal
              és az ott meghatározott egyéb címeken elérhető weboldalak (a
              továbbiakban: „weboldal”) által nyújtott szolgáltatásaihoz
              kapcsolódóan a felhasználó természetes személyek valamennyi
              adatkezelése során a jelen Adatkezelési Szabályzat és tájékoztató
              alapján jár el.
              <div className='data__gdpr--general'>
                <p>
                  A Felhasználó a weboldalra történő belépéssel, és a weboldal
                  használatával magára nézve kötelezőnek fogadja el a jelen
                  Adatkezelési Szabályzat rendelkezéseit.
                </p>
                <p>Adatkezelő a Jelen Szabályzat tekintetében:</p>
                <ol className='data__gdpr--alphalist'>
                  <li>
                    Adatkezelő: <strong>CPP-TRADE Bt.</strong>
                  </li>
                  <li>
                    Székhelye:
                    <strong>4030 Debrecen, Vukovics Sebő u. 10.</strong>
                  </li>
                  <li>
                    Postacíme:
                    <strong>4030 Debrecen, Vukovics Sebő u. 10.</strong>
                  </li>
                  <li>
                    Elektronikus (e-mail) cím: <strong>info@cpptrade.hu</strong>
                  </li>
                  <li>
                    Nyilvántartó bíróság:
                    <strong>Debreceni Törvényszék Cégbírósága</strong>
                  </li>
                  <li>
                    Cégjegyzékszáma: <strong>09-06-010295</strong>
                  </li>
                  <li>
                    Adószáma: <strong>20947905-2-09</strong>
                  </li>
                </ol>
              </div>
              <p>
                <strong>(2)</strong> Az adatvédelmi tájékoztató célja, hogy
                meghatározza az Adatkezelő által kezelt személyes adatok körét,
                az adatkezelés módját, valamint biztosítsa az adatvédelem
                alkotmányos elveinek, az adatbiztonság követelményeinek
                érvényesülését, s megakadályozza az adatokhoz való jogosulatlan
                hozzáférést, az adatok megváltoztatását és jogosulatlan
                nyilvánosságra hozatalát, vagy felhasználását, annak érdekében,
                hogy a felhasználó természetes személyek magánszférájának a
                tiszteletben tartása megvalósuljon.
              </p>
              <p>
                <strong>(3)</strong> Adatkezelő az <strong>(2)</strong>{" "}
                bekezdésben megfogalmazott cél érdekében a felhasználó személyes
                adatait bizalmasan, a hatályos jogszabályi előírásokkal
                összhangban kezeli, gondoskodik azok biztonságáról, megteszi
                azokat a technikai és szervezési intézkedéseket, valamint
                kialakítja azokat az eljárási szabályokat, amelyek a vonatkozó
                jogszabályi rendelkezések és más ajánlások érvényre juttatásához
                szükségesek.
              </p>
            </p>
          </div>
        </li>
        <li>
          Jogszabályi háttér
          <div className='data__gdpr--topic'>
            <p>
              A személyes adatok kezelésével kapcsolatos jogszabályi előírásokat
              Adatkezelő az adatkezelés minden fázisában köteles betartani.
              Adatkezelő által végzett adatkezelésre elsősorban az alábbi
              jogszabályokban rögzített rendelkezések az irányadóak:
            </p>
            <ul>
              <li>
                a Polgári Törvénykönyvről szóló 2013. évi V. törvény 2:43§ (e)
              </li>
              <li>
                az információs önrendelkezési jogról és az
                információszabadságról szóló 2011. évi CXII. tv. („Adatvédelmi
                tv.”);
              </li>
              <li>
                az elektronikus kereskedelmi szolgáltatások, valamint
                információs társadalommal összefüggő szolgáltatások egyes
                kérdéseiről szóló 2001. évi CVIII. tv. („Eker. tv.”);
              </li>
              <li>
                a gazdasági reklámtevékenység alapvető feltételeiről és egyes
                korlátairól szóló 2008. évi XLVIII. tv. („Grt. tv.”)
              </li>
              <li>
                az egyének védelméről a személyes adatok gépi feldolgozása
                során, Strasbourgban, 1981. január 28. napján kelt Egyezmény
                kihirdetéséről szóló 1998. évi VI. törvény;
              </li>
              <li>
                a kutatás és a közvetlen üzletszerzés célját szolgáló név- és
                lakcímadatok kezeléséről szóló 1995. évi CXIX. Törvény („Katv.”)
              </li>
            </ul>
          </div>
        </li>
        <li>
          Fogalmak
          <div className='data__gdpr--topic'>
            <ul className='data__gdpr--nolisttype'>
              <li>
                <strong>(1) érintett:</strong> bármely meghatározott, személyes
                adat alapján azonosított vagy - közvetlenül vagy közvetve -
                azonosítható természetes személy;
              </li>
              <li>
                <strong>(2) személyes adat:</strong> az érintettel kapcsolatba
                hozható adat - különösen az érintett neve, azonosító jele,
                valamint egy vagy több fizikai, fiziológiai, mentális,
                gazdasági, kulturális vagy szociális azonosságára jellemző
                ismeret -, valamint az adatból levonható, az érintettre
                vonatkozó következtetés;
              </li>
              <li>
                <strong>(3) hozzájárulás:</strong> az érintett kívánságának
                önkéntes és határozott kinyilvánítása, amely megfelelő
                tájékoztatáson alapul, és amellyel félreérthetetlen
                beleegyezését adja a rá vonatkozó személyes adatok - teljes körű
                vagy egyes műveletekre kiterjedő - kezeléséhez;
              </li>
              <li>
                <strong>(4) tiltakozás:</strong> az érintett nyilatkozata,
                amellyel személyes adatainak kezelését kifogásolja, és az
                adatkezelés megszüntetését, illetve a kezelt adatok törlését
                kéri
              </li>
              <li>
                <strong>(5) adatkezelés:</strong> az alkalmazott eljárástól
                függetlenül a személyes adatokon végzett bármely művelet vagy a
                műveletek összessége, így például gyűjtése, felvétele,
                rögzítése, rendszerezése, tárolása, megváltoztatása,
                felhasználása, továbbítása, nyilvánosságra hozatala,
                összehangolása vagy összekapcsolása, zárolása, törlése és
                megsemmisítése, valamint az adatok további felhasználásának
                megakadályozása, fénykép-, hang- vagy képfelvétel készítése,
                valamint a személy azonosítására alkalmas fizikai jellemzők (pl:
                ujj-, tenyérlenyomat, DNS-minta, íriszkép) rögzítése
              </li>
              <li>
                <strong>(6) adatfeldolgozás:</strong> az adatkezelési
                műveletekhez kapcsolódó technikai feladatok elvégzése,
                függetlenül a műveletek végrehajtásához alkalmazott módszertől
                és eszköztől, valamint az alkalmazás helyétől, feltéve, hogy a
                technikai feladatot az adatokon végzik.
              </li>
              <li>
                <strong>(7) adattovábbítás:</strong> az adat meghatározott
                harmadik személy számára történő hozzáférhetővé tétele.
              </li>
              <li>
                <strong>(8) nyilvánosságra hozatal:</strong> az adatok bárki
                számára történő hozzáférhetővé tétele.
              </li>
              <li>
                <strong>(9) adatkezelő:</strong> az a természetes vagy jogi
                személy, illetve jogi személyiséggel nem rendelkező szervezet,
                aki, vagy amely önállóan vagy másokkal együtt a személyes adatok
                kezelésének célját meghatározza, az adatkezelésre (beleértve a
                felhasznált eszközt) vonatkozó döntéseket meghozza és
                végrehajtja, vagy az általa megbízott adatfeldolgozóval
                végrehajtatja.
              </li>
              <li>
                <strong>(10) adatfeldolgozó:</strong> az a természetes vagy jogi
                személy, illetve jogi személyiséggel nem rendelkező szervezet,
                aki, vagy amely szerződés alapján - beleértve a jogszabály
                rendelkezése alapján kötött szerződést is - adatok feldolgozását
                végzi.
              </li>
              <li>
                <strong>(11) adattörlés:</strong> az adatok felismerhetetlenné
                tétele oly módon, hogy a helyreállításuk többé nem lehetséges.
              </li>
              <li>
                <strong>(12) adatállomány:</strong> az egy nyilvántartásban
                kezelt adatok összessége.
              </li>
              <li>
                <strong>(13) harmadik személy:</strong> olyan természetes vagy
                jogi személy, illetve jogi személyiséggel nem rendelkező
                szervezet, aki, vagy amely nem azonos az érintettel, az
                adatkezelővel vagy az adatfeldolgozóval;
              </li>
            </ul>
          </div>
        </li>
        <li>
          Az adatkezelés jogalapja
          <div className='data__gdpr--topic'>
            <p>
              Adatkezelő az Érintettek adatait a hatályos adatvédelemre
              vonatkozó jogszabályoknak megfelelően, hozzájárulásuk alapján,
              illetőleg
            </p>
            <ul>
              <li>
                az elektronikus kereskedelmi szolgáltatások, valamint az
                információs társadalommal összefüggő szolgáltatások egyes
                kérdéseiről szóló 2001. évi CVIII. tv. 13/A §-a
              </li>
              <li>
                a gazdasági reklámtevékenység alapvető feltételeiről és egyes
                korlátairól szóló 2008. évi XLVIII. tv. 6. § bekezdése alapján
                kezeli.
              </li>
            </ul>
          </div>
        </li>
        <li>
          A kezelt adatok köre, az adatkezelés célja és időtartama
          <div className='data__gdpr--topic'>
            <p>
              <strong>(1)</strong>
            </p>
            <p>
              A jelen Adatvédelmi Szabályzat kizárólag természetes személyek
              adatainak kezelésére terjed ki, tekintettel arra, hogy személyes
              adatok kizárólag természetes személyek vonatkozásában
              értelmezhetőek. Nem minősülnek személyes adatnak azok az anonim
              információk, amelyeket a személyes azonosíthatóság kizárásával
              gyűjt adatkezelő, és természetes személlyel nem hozhatóak
              kapcsolatba, illetve azok a demográfiai adatok sem minősülnek
              személyes adatnak, melyeket úgy gyűjt, hogy nem kapcsolja hozzá
              azokat azonosítható személyek személyes adataihoz, ezáltal nem
              állítható fel kapcsolat természetes személlyel.
            </p>
            <p>
              <strong>(2)</strong>
            </p>
            <h3>Üzenetküldés, Árajánlat kérése</h3>
            <p>
              A weboldalon lehetőség van a Szolgáltató által nyújtott
              szolgáltatásokkal kapcsolatosan árajánlat, illetőleg egyéb
              információ kérésére, melynek során az alábbi személyes adatok
              megadása szükséges:
            </p>
            <ul>
              <li>e-mail cím</li>
              <li>név</li>
              <li>telefonszám</li>
            </ul>
            <p>
              Az adatkezelés célja: az Érintettek személyre szabott
              kiszolgálása, illetve az Érintettek kérésére árajánlat küldése.
            </p>
            <h3>Anonim igénybe vevő azonosító (cookie) </h3>
            <p>
              Az Adatkezelő az Érintett számítógépére anonim igénybe vevő
              azonosítót (cookie, süti) helyez el, amely önmagában semmilyen
              módon nem képes az Érintett azonosítására, kizárólag az Érintett
              gépének felismerésére alkalmas, név, e-mail cím vagy bármilyen más
              személyes információ megadása nem szükséges, mivel a megoldás
              alkalmazásakor a Felhasználó az Adatkezelőnek nem ad át személyes
              adatot, az adatcsere csak és kizárólag a gépek között történik
              meg.
            </p>
            <p>
              Adatkezelő a cookie-kat abból a célból kezeli, hogy többet
              tudhasson meg az Érintettek információhasználati szokásairól és
              így, javíthassa szolgáltatásai színvonalát, valamint a Portál
              látogatása során testreszabott oldalakat, marketing (reklám)
              anyagokat jelentessen meg.
            </p>
            <p>
              Az Érintettnek lehetősége van arra, hogy böngészője beállításával
              megtiltsa az egyedei azonosító jel (cookie) elhelyezését a
              számítógépén. Az Érintett tudomásul veszi, hogy a cookie-k
              letiltása esetén egyes szolgáltatások nem fognak megfelelően
              működni.
            </p>
            <p>
              Az érintett a hozzájárulását az alábbi szöveggel felugró ablak
              „elfogadom” gombjával, illetve a weboldaon történő továbblépéssel,
              (bármely link vagy menüpontra kattintással) teheti meg. „Az
              oldalon történő látogatása során cookie-kat ("sütiket")
              használunk. Ezen fájlok információkat szolgáltatnak számunkra a
              felhasználó oldallátogatási szokásairól, de nem tárolnak személyes
              információkat. Az oldalon történő továbblépéssel elfogadja a
              cookie-k használatát.
            </p>
            <h3>
              Közösségi bővítmények (Facebook, Twitter, Linked-in) használata
            </h3>
            <p>
              A Portálon a bővítmények alaphelyzetben le vannak tiltva. A
              bővítmények csak akkor kerülnek engedélyezésre, ha az Érintett az
              erre szolgáló gombra kattint. A bővítmény engedélyezésével az
              Érintett kapcsolatot hoz létre a közösségi oldallal és hozzájárul
              az adatainak a Facebook/Twitter/Linked-in részére történő
              továbbításához. Amennyiben az Érintett be van jelentkezve a
              Facebookra/Twitterre/Linked-inre, előfordulhat, hogy az adott
              közösségi hálózat a látogatását az Érintett közösségi fiókjához
              társítja.
            </p>
            <p>
              Amennyiben az Érintett a megfelelő gombra kattint, a böngészője a
              vonatkozó információkat közvetlenül az adott közösségi hálózatnak
              továbbítja, és azokat ott tárolja.
            </p>
            <p>
              Az adatgyűjtés körére és céljára, adatainak a
              Facebook/Twitter/Linked-in általi további feldolgozására és
              felhasználására, személyes adatainak védelmére irányuló jogaira és
              beállításaira vonatkozó információk a Facebook//Twitter/Linked-in
              adatvédelmi nyilatkozatában találhatóak.
            </p>
            <h3>Remarketing kódok</h3>
            <p>
              A Portálon a Szolgáltató Google Adwords, valamint facebook
              remarketing kódokat használ. A remarketing kód cookie-kat használ,
              a Portál látogatóinak megcímkézéséhez. A települt cookie segít
              abban, hogy a Szolgáltató termékeivel és szolgáltatásaival
              kapcsolatos hirdetések jelenjenek meg a Portál látogatója által a
              későbbiekben meglátogatott más, a Google Display, hálózatába
              tartozó weboldalakon, illetve a facebook-on. Felhasználó a
              cookie-kat bármikor letilthatja és a hirdetések személyre
              szabhatja a Google hirdetési beállítások felületén.
            </p>
            <p>
              Az érintett a hozzájárulását az alábbi szöveggel felugró ablak
              „elfogadom” gombjával, illetve a weboldaon történő továbblépéssel,
              (bármely link vagy menüpontra kattintással) teheti meg. „Az
              oldalon történő látogatása során cookie-kat ("sütiket")
              használunk. Ezen fájlok információkat szolgáltatnak számunkra a
              felhasználó oldallátogatási szokásairól, de nem tárolnak személyes
              információkat. Az oldalon történő továbblépéssel elfogadja a
              cookie-k használatát.
            </p>
            <h3>Naplóállományok (logfile-ok)</h3>
            <p>
              A szolgáltatások igénybevétele érdekében a rendszer automatikusan
              naplózza az alábbi adatokat:
            </p>
            <ul>
              <li>a felhasználó számítógépének dinamikus IP-címe</li>
              <li>
                a felhasználó számítógépének beállításaitól függően a
                felhasználó által használt böngésző és az operációs rendszer
                típusa
              </li>
              <li>a felhasználó weboldallal kapcsolatos aktivitása</li>
            </ul>
            <p>
              Ezen adatok felhasználása egyrészről technikai célok – úgymint a
              szerverek biztonságos működésének elemzése, utólagos ellenőrzése-
              szolgál, másrészről az Adatkezelő ezen adatokat oldalhasználati
              statisztikák készítéséhez, a felhasználói igények elemzéséhez
              használja fel a szolgáltatások színvonalának emelése érdekében. A
              fenti adatok a felhasználó azonosítására nem alkalmasak és azokat
              az Adatkezelő egyéb személyes adatokkal nem kapcsolja össze.
            </p>
            <p>
              Az érintett a hozzájárulását az alábbi szöveggel felugró ablak
              „elfogadom” gombjával, illetve a weboldaon történő továbblépéssel,
              (bármely link vagy menüpontra kattintással) teheti meg. „Az
              oldalon történő látogatása során cookie-kat ("sütiket")
              használunk. Ezen fájlok információkat szolgáltatnak számunkra a
              felhasználó oldallátogatási szokásairól, de nem tárolnak személyes
              információkat. Az oldalon történő továbblépéssel elfogadja a
              cookie-k használatát.
            </p>
            <p>
              <strong>(3)</strong>
            </p>
            <p>
              Adatkezelő az Érintettel kapcsolatos személyes adatokat bármely, a
              fentiekben meghatározottaktól eltérő célból - így különösen
              szolgáltatása hatékonyságának növelése, vagy piackutatás céljából
              - csak az adatkezelési cél előzetes meghatározása mellett és az
              Érintett hozzájárulása alapján kezelhet. Ezek az adatok nem
              kapcsolhatók össze az Érintett azonosító adataival és a
              hozzájárulása nélkül nem adhatók át harmadik személy számára. Ezen
              adatokat a Adatkezelő köteles törölni, ha az adatkezelési cél
              megszűnt, vagy az Érintett így rendelkezik.
            </p>
            <p>
              <strong>(4)</strong>
            </p>
            <p>
              Az Adatkezelő biztosítja, hogy az igénybe vevő a szolgáltatás
              igénybevétele előtt és az igénybevétel során bármikor
              megismerhesse, hogy az Adatkezelő mely adatkezelési célokból mely
              adatfajtákat kezel, ideértve az igénybe vevővel közvetlenül
              kapcsolatba nem hozható adatok kezelését is.
            </p>
            <p>
              <strong>(5)</strong>
            </p>
            <p>
              Az Adatkezelő által végzett adatkezelés jogalapja minden esetben
              az Érintett hozzájárulása.
            </p>
            <p>
              <strong>(6)</strong>
            </p>
            <h3>Az adatkezelés időtartama</h3>
            <p>
              Az Érintett hozzájárulása alapján kezelt adatok a hozzájárulás
              módosításáig, illetőleg visszavonásáig kezelhetők. Az adatkezelés
              időtartamának lejártával az Érintett személyes adatait az
              Adatkezelő törölni köteles.
            </p>
            <p>
              A megrendelésekkel kapcsolatos adatokat – ideértve a telefonos
              ügyintézés során készült hangfelvételt is - az Adatkezelő az
              esetleges jogviták során történő bizonyítás érdekében, az
              általános elévülési határidőig, azaz 5 (öt) évig tárolja. A
              számlázással kapcsolatos adatokat az Adatkezelő a számviteli
              kötelezettségek teljesítés érdekében, a 2000. évi C. törvény 169.
              §-a értelmében 8 (nyolc) évig, illetve az adózás rendjéről szóló
              2003. évi XCII. Törvényben meghatározott elévülési időig kezeli.
            </p>
            <p>
              <strong>(7)</strong>
            </p>
            <p>
              Előfordulhat, hogy a szolgáltatások teljes körű megvalósításához
              az Érintett bizonyos személyes adatait az Adatkezelő harmadik fél
              részére - ideiglenes jelleggel - a szükséges hozzájárulás megadása
              esetén - adatfeldolgozás vagy adatkezelés céljából - átadja, így
              különösen:
            </p>
            <ul>
              <li>
                amennyiben a weboldalon keresztül online fizetésre kerül sor, az
                Adatkezelő a fizetéshez szükséges hitelkártya/bankkártya számát
                továbbítja a pénzintézeti szolgáltató felé, anélkül, hogy azt
                megőrizné.
              </li>
              <li>
                amennyiben a weboldalon megrendelt termékek esetén a
                kiszállításra szerződött partnervállalat számára az Adatkezelő
                átadja a szállítandó terméket és a szállítás teljesítéséhez
                szükséges adatokat (a szállítási nevet és címet). A kiszállítást
                végző partner az átadott szállítási adatok vonatkozásában
                adatfeldolgozónak minősül, aki ezeket az adatokat a szállítás
                teljesítésén kívül más célokra nem használhatja fel.
              </li>
            </ul>
            <p>
              Az érintett a hozzájárulását az adatvédelmi szabályzat
              elfogadásával teheti meg.
            </p>
            <p>
              <strong>(8)</strong>
            </p>
            <p>
              A weboldal független látogatottsági és egyéb webanalitikai adatok
              kinyerése érdekében a szolgáltató igénybe veszi a Google Anatitycs
              szoftvert, ezért ezen adatok vonatkozásában a Google Inc., mint
              adatfeldolgozó jár el. A Google Inc. Adatvédelmi irányelve a{" "}
              <a href='http://www.google.com/intl/hu_ALL/privacypolicy.html'>
                http://www.google.com/intl/hu_ALL/privacypolicy.html
              </a>{" "}
              oldalon érhető el. A weboldal szolgáltatásait igénybe vevő
              tudomásul veszi, hogy az adatainak Google általi feldolgozásához a
              weboldal használatával hozzájárulását adta
            </p>
            <p>
              <strong>(9)</strong>
            </p>
            <p>
              Amennyiben olyan szolgáltatásokról van szó, amelynek során,
              személyes adatokat – így például online fizetés esetén bankkártya
              számot – kell a Felhasználónak online módon elküldenie a
              szolgáltatás igénybevételéhez, az Adatkezelő az ilyen üzenetek
              számára megfelelő védelmet nyújtó csatornát - SSL-alapú
              csatlakozást – biztosít.
            </p>
            <p>
              <strong>(10)</strong>
            </p>
            <p>
              Amennyiben a weboldal egyes szolgáltatásait és oldalait a
              Szolgáltató a vele üzleti kapcsolatban álló céggel üzemelteti, a
              Szolgáltató üzemeltető partnere - a Szolgáltató nevében és
              képviseletében eljárva a Szolgáltató javára - gyűjti a személyes
              adatokat, amely adatkezelésre szintén a jelen Adatvédelmi
              Szabályzat rendelkezései vonatkoznak.
            </p>
            <p>
              <strong>(11)</strong>
            </p>
            <p>
              Amennyiben a weboldal valamely tartalompartnerével közös
              szolgáltatást tart fenn, a személyes adatok felhasználási joga
              közös, azonban a jelen Adatkezelési Szabályzatban foglaltak - a
              partnerrel fennálló szerződéses kapcsolat során előírt azonos
              tartalmú adatkezelésre vonatkozó szabályoknak megfelelően - ekkor
              is irányadóak.
            </p>
            <p>
              <strong>(12)</strong>
            </p>
            <p>
              A <strong>(7)-(11)</strong> bekezdésben említett adatkezelések
              esetében az adatszolgáltatás illetve adatfeldolgozás során
              egyértelműen feltüntetésre kerül az adatkezelő illetve az
              adatfeldolgozó személye.
            </p>
          </div>
        </li>
        <li>
          Az Érintettek jogai
          <div className='data__gdpr--topic'>
            <p>
              <strong>(1)</strong>
            </p>
            <p>Az Érintett kérelmezheti az Adatkezelőnél</p>
            <ol className='data__gdpr--alphalist'>
              <li>tájékoztatását személyes adatai kezeléséről,</li>
              <li>személyes adatainak helyesbítését, valamint</li>
              <li>
                személyes adatainak - a kötelező adatkezelés kivételével -
                törlését vagy zárolását.
              </li>
            </ol>
            <p>
              <strong>(2)</strong>
            </p>
            <p>
              Az Érintett kérelmére az Adatkezelő, legkésőbb az erre irányuló
              kérelem benyújtásától számított 30 napon belül írásbeli
              tájékoztatást az Érintett általa kezelt, illetve az általa vagy
              rendelkezése szerint megbízott adatfeldolgozó által feldolgozott
              adatairól, azok forrásáról, az adatkezelés céljáról, jogalapjáról,
              időtartamáról, az adatfeldolgozó nevéről, címéről és az
              adatkezeléssel összefüggő tevékenységéről, továbbá - az érintett
              személyes adatainak továbbítása esetén - az adattovábbítás
              jogalapjáról és címzettjéről. A tájékoztatás ingyenes, ha a
              tájékoztatást kérő a folyó évben azonos területre vonatkozó
              tájékoztatási kérelmet az Adatkezelőhöz még nem nyújtott be. Egyéb
              esetben az Adatkezelő költségtérítést állapít meg, azzal, hogy a
              már megfizetett költségtérítést vissza kell téríteni, ha az
              adatokat jogellenesen kezelték, vagy a tájékoztatás kérése
              helyesbítéshez vezetett.
            </p>
            <p>
              <strong>(3)</strong>
            </p>
            <p>
              Az Adatkezelő az adattovábbítás jogszerűségének ellenőrzése,
              valamint az érintett tájékoztatása céljából adattovábbítási
              nyilvántartást vezet, amely tartalmazza az általa kezelt személyes
              adatok továbbításának időpontját, az adattovábbítás jogalapját és
              címzettjét, a továbbított személyes adatok körének meghatározását,
              valamint az adatkezelést előíró jogszabályban meghatározott egyéb
              adatokat.
            </p>
            <p>
              <strong>(4)</strong>
            </p>
            <p>
              Ha a személyes adat a valóságnak nem felel meg, és a valóságnak
              megfelelő személyes adat az Adatkezelő rendelkezésére áll, a
              személyes adatot az Adatkezelő helyesbíti.
            </p>
            <p>
              <strong>(5)</strong>
            </p>
            <p>A személyes adatot törölni kell, ha</p>
            <ol className='data__gdpr--alphalist'>
              <li>kezelése jogellenes;</li>
              <li>az Érintett kéri (a kötelező adatkezelés kivételével);</li>
              <li>
                az hiányos vagy téves - és ez az állapot jogszerűen nem
                orvosolható -, feltéve, hogy a törlést törvény nem zárja ki;
              </li>
              <li>
                az adatkezelés célja megszűnt, vagy az adatok tárolásának
                törvényben meghatározott határideje lejárt;
              </li>
              <li>
                azt a bíróság vagy a Nemzeti Adatvédelmi és Információszabadság
                Hatóság elrendelte.
              </li>
            </ol>
            <p>
              <strong>(6)</strong>
            </p>
            <p>
              Törlés helyett az Adatkezelő zárolja a személyes adatot, ha az
              Érintett ezt kéri, vagy ha a rendelkezésére álló információk
              alapján feltételezhető, hogy a törlés sértené az Érintett jogos
              érdekeit. Az így zárolt személyes adat kizárólag addig kezelhető,
              ameddig fennáll az az adatkezelési cél, amely a személyes adat
              törlését kizárta.
            </p>
            <p>
              <strong>(7)</strong>
            </p>
            <p>
              Az Adatkezelő megjelöli az általa kezelt személyes adatot, ha az
              érintett vitatja annak helyességét vagy pontosságát, de a vitatott
              személyes adat helytelensége vagy pontatlansága nem állapítható
              meg egyértelműen.
            </p>
            <p>
              <strong>(8)</strong>
            </p>
            <p>
              A helyesbítésről, a zárolásról, a megjelölésről és a törlésről az
              Érintettet, továbbá mindazokat értesíteni kell, akiknek korábban
              az adatot adatkezelés céljára továbbították. Az értesítés
              mellőzhető, ha ez az adatkezelés céljára való tekintettel az
              érintett jogos érdekét nem sérti.
            </p>
            <p>
              <strong>(9)</strong>
            </p>
            <p>
              Ha az Adatkezelő az érintett helyesbítés, zárolás vagy törlés
              iránti kérelmét nem teljesíti, a kérelem kézhezvételét követő 30
              napon belül írásban közli a helyesbítés, zárolás vagy törlés
              iránti kérelem elutasításának ténybeli és jogi indokait. A
              helyesbítés, törlés vagy zárolás iránti kérelem elutasítása esetén
              az Adatkezelő tájékoztatja az Érintettet a bírósági jogorvoslat,
              továbbá a Hatósághoz fordulás lehetőségéről.
            </p>
            <p>
              <strong>(10)</strong>
            </p>
            <p>
              <em>(1)</em> Az Érintett tiltakozhat személyes adatának kezelése
              ellen, ha
            </p>
            <ol className='data__gdpr--alphalist'>
              <li>
                ha a személyes adatok kezelése vagy továbbítása kizárólag az
                adatkezelőre vonatkozó jogi kötelezettség teljesítéséhez vagy az
                adatkezelő, adatátvevő vagy harmadik személy jogos érdekének
                érvényesítéséhez szükséges, kivéve kötelező adatkezelés esetén;
              </li>
              <li>
                ha a személyes adat felhasználása vagy továbbítása közvetlen
                üzletszerzés, közvélemény-kutatás vagy tudományos kutatás
                céljára történik; valamint
              </li>
              <li>törvényben meghatározott egyéb esetben.</li>
            </ol>
            <p>
              <em>(2)</em> Az Adatkezelő a tiltakozást a kérelem benyújtásától
              számított legrövidebb időn belül, de legfeljebb 15 napon belül
              megvizsgálja, annak megalapozottsága kérdésében döntést hoz, és
              döntéséről a kérelmezőt írásban tájékoztatja.
            </p>
            <p>
              <em>(3)</em> Amennyiben a tiltakozás indokolt, az Adatkezelő
              köteles az adatkezelést - beleértve a további adatfelvételt és
              adattovábbítást is - megszüntetni és az adatokat zárolni, valamint
              a tiltakozásról, illetőleg az annak alapján tett intézkedésekről
              értesíteni mindazokat, akik részére a tiltakozással érintett
              személyes adatot korábban továbbította, és akik kötelesek
              intézkedni a tiltakozási jog érvényesítése érdekében.
            </p>
            <p>
              <em>(4)</em> Ha az érintett az adatkezelőnek a <em>(2)</em>{" "}
              bekezdés alapján meghozott döntésével nem ért egyet, illetve ha az
              adatkezelő a <em>(2)</em> bekezdés szerinti határidőt elmulasztja,
              az érintett - a döntés közlésétől, illetve a határidő utolsó
              napjától számított 30 napon belül - a 22. §-ban meghatározott
              módon bírósághoz fordulhat.
            </p>
            <p>
              <strong>(11)</strong>
            </p>
            <p>
              Az Érintettnek jelen 5. pontban meghatározott jogait törvény
              korlátozhatja az állam külső és belső biztonsága, így a
              honvédelem, a nemzetbiztonság, a bűncselekmények megelőzése vagy
              üldözése, a büntetés-végrehajtás biztonsága érdekében, továbbá
              állami vagy önkormányzati gazdasági vagy pénzügyi érdekből, az
              Európai Unió jelentős gazdasági vagy pénzügyi érdekéből, valamint
              a foglalkozások gyakorlásával összefüggő fegyelmi és etikai
              vétségek, a munkajogi és munkavédelmi kötelezettségszegések
              megelőzése és feltárása céljából - beleértve minden esetben az
              ellenőrzést és a felügyeletet is -, továbbá az Érintett vagy mások
              jogainak védelme érdekében.
            </p>
            <p>
              <strong>(12)</strong>
            </p>
            <p>
              A jelen tájékoztatóban nem szabályozott kérdésekre a 2. pontban
              megjelölt jogszabályok rendelkezései az irányadóak.
            </p>
          </div>
        </li>
        <li>
          Jogorvoslati lehetőségek
          <div className='data__gdpr--topic'>
            <p>
              <strong>(1)</strong>
            </p>
            <p>Az Érintett jogsértés esetén jogorvoslatért fordulhat:</p>
            <ol className='data__gdpr--alphalist'>
              <li>
                az Alapvető Jogok Biztosának Hivatalához (1051 Budapest, Nádor
                u. 22.),
              </li>
              <li>
                a Nemzeti Adatvédelmi és Információszabadság Hatósághoz
                <br />
                Székhely: 1125 Budapest, Szilágyi Erzsébet fasor 22/c.
                <br /> Postacím: 1530 Budapest, Pf. 5. <br />
                Telefon: 06 -1- 391-1400 <br />
                Telefax: 06-1-391-1410 <br />
                <a
                  href='mailto:ugyfelszolgalat@naih.hu'
                  rel='noreferrer'
                  target='_blank'>
                  ugyfelszolgalat@naih.hu
                </a>
              </li>
              <li>
                az Érintett lakóhelye, ill. Tartózkodási helye szerint illetékes
                Törvényszéknél.
              </li>
            </ol>
            <p>
              A bíróság az ügyben soron kívül jár el. Az adatkezelés
              jogszerűségét az Adatkezelő, az adatátvétel jogszerűségét az
              adatátvevő köteles bizonyítani. Ha a bíróság a kérelemnek helyt
              ad, a Adatkezelőt a tájékoztatás megadására, az adat
              helyesbítésére, zárolására, törlésére, az automatizált
              adatfeldolgozással hozott döntés megsemmisítésére, az érintett
              tiltakozási jogának figyelembevételére, illetve az Info tv. 21.
              §-ban meghatározott adatátvevő által kért adat kiadására kötelezi.
              Ha a bíróság az Info tv. 21. §-ban meghatározott esetekben az
              adatátvevő kérelmét elutasítja, az adatkezelő köteles az érintett
              személyes adatát az ítélet közlésétől számított 3 napon belül
              törölni.
            </p>
            <p>
              Adatkezelő köteles az adatokat akkor is törölni, ha az adatátvevő
              az Info tv. 21. § (5), illetve (6) bekezdésében meghatározott
              határidőn belül nem fordul bírósághoz. A bíróság elrendelheti
              ítéletének – a Adatkezelő azonosító adatainak közzétételével
              történő - nyilvánosságra hozatalát, ha azt az adatvédelem érdekei
              és nagyobb számú érintett e törvényben védett jogai megkövetelik.
            </p>
            <p>
              <strong>(2)</strong>
            </p>
            <p>
              Az Adatkezelő az Érintett adatainak jogellenes kezelésével vagy az
              adatbiztonság követelményeinek megszegésével másnak okozott kárt
              köteles megtéríteni. Az Érintettel szemben az Adatkezelő felel az
              adatfeldolgozó által okozott kárért is. Az Adatkezelő mentesül a
              felelősség alól, ha bizonyítja, hogy a kárt az adatkezelés körén
              kívül eső elháríthatatlan ok idézte elő.
            </p>
            <p>
              Nem kell megtéríteni a kárt annyiban, amennyiben az a károsult
              szándékos vagy súlyosan gondatlan magatartásából származott.
            </p>
          </div>
        </li>
      </ol>
    </div>
  );
}

export default DataGdpr;
