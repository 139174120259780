import { useState } from "react";

function ProductHeader(props) {
  const { children, product } = props;
  const [expanded, setExpanded] = useState(props.expanded);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={
        "product__collapsible " +
        (expanded ? "product__collapsible--expanded" : "")
      }>
      <div className='product__collapsible__header'>
        <h2>{product.title}</h2>
        <span
          onClick={() => toggleExpanded()}
          className={
            "chevron collapsible__chevron " +
            (expanded ? "collapsible--expanded" : "")
          }
        />
      </div>
      {children}
    </div>
  );
}

export default ProductHeader;
