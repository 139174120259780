import Product from "./product";
import ProductHeader from "./product-header";

function ProductContainer({ product, producerName, expanded }) {
  return (
    <ProductHeader product={product} expanded={expanded}>
      <Product product={product} producerName={producerName} />
    </ProductHeader>
  );
}

export default ProductContainer;
