import { useParams } from "react-router-dom";
import { products } from "./../data/products";

export const useProducer = () => {
  const { producer } = useParams();
  const producersProducts = products.filter(
    (item) => item.producerName === producer
  );
  return producersProducts?.length > 0 ? producersProducts[0] : null;
};
