function ProductFeatures({ features }) {
  return (
    <div className='product__details'>
      <h3 className='product__detail__title'>Tulajdonságok</h3>
      <div>
        <table className='product__detail__table'>
          <tbody>
            {features.map((feature, index) => {
              return (
                <tr key={index}>
                  <td className='product__detail__name'>
                    <strong>{feature.name}</strong>
                  </td>
                  <td className='product__detail__data'>
                    <strong>{feature.value}</strong>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ProductFeatures;
