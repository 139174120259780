import ColorPicker from "./colorpicker";
import { colors } from "./../data/colors";

function ColorPalette({ onColorClick, count }) {
  return (
    <div className='product__color__container'>
      <h3 className='product__color__heading'>Választható színek</h3>
      <div className='product__colors'>
        {colors.slice(0, count).map((color, index) => {
          const { fileName, rgb, border, description } = color;
          return (
            <ColorPicker
              key={index}
              fileName={fileName}
              rgb={rgb}
              border={border}
              name={description}
              onClick={(src) => onColorClick(src)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ColorPalette;
