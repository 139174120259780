import { useState } from "react";
import ColorPalette from "./color-palette";
import ProductFeatures from "./product-features";
import ProductAppendix from "./product-appendix";

function Product({ product, producerName }) {
  const path = `/images/${producerName}/${product.name}/`;
  const [pictureFile, setPictureFile] = useState(path + "white.jpg");

  const handleColorClick = (fileName) => {
    setPictureFile(path + fileName);
  };

  return (
    <div className='product product__collapsible__content'>
      <div className='product__images'>
        <div className='product__image'>
          <img className='the__image' src={pictureFile} alt='' />
        </div>
        <div className='product__image'>
          <img src={path + "draw.jpg"} alt='' />
        </div>
      </div>
      <div className='product__data'>
        <ColorPalette onColorClick={handleColorClick} count={product.colors} />
        <ProductFeatures features={product.features} />
        <ProductAppendix descriptions={product.descriptions} />
      </div>
    </div>
  );
}

export default Product;
