import { ReactComponent as MenuIcon } from "../images/icons8-menu.svg";
import { useState } from "react";
import { Link } from "react-router-dom";

function NavBar() {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const expandedClass = expanded ? " collapsible--expanded" : "";

  return (
    <div>
      <span
        className={"icon-container nav__toggler" + expandedClass}
        onClick={() => toggleExpanded()}>
        <svg className='icon icon--white'>
          <MenuIcon />
        </svg>
      </span>

      <nav className={"nav collapsible" + expandedClass}>
        <ul className='list nav__list collapsible__content'>
          <li className='nav__item'>
            <Link to='/'>Forgalmazott termékek</Link>
          </li>
          <li className='nav__item'>
            <Link to='/action'>Akciós ajánlatok</Link>
          </li>
          <li className='nav__item'>
            <Link to='/download'>Letöltések</Link>
          </li>
          <li className='nav__item'>
            <Link to='/connection'>Kapcsolat</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default NavBar;
