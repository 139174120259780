function ColorPicker({ fileName, rgb, border, name, onClick }) {
  return (
    <div
      className='product__color'
      src={fileName}
      onClick={() => onClick(fileName)}>
      <span
        className='product__color__item'
        style={{
          backgroundColor: rgb,
          border: border ? "1px solid black" : "none",
        }}></span>
      <span className='product__color__name'>{name}</span>
    </div>
  );
}

export default ColorPicker;
