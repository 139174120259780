import ProducerCaption from "./producer-caption";
import { useProducer } from "./../hooks/useProducer";
import ProductCategory from "./product-category";

function Producer() {
  const producer = useProducer();
  return (
    <div style={{ backgroundImage: "url(/images/bg-pattern.jpg)" }}>
      <ProducerCaption producer={producer} />
      {producer.categories.map((category, index) => {
        return (
          <ProductCategory
            first={index === 0}
            category={category}
            producerName={producer.producerName}
          />
        );
      })}
    </div>
  );
}

export default Producer;
