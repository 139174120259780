function ProductAppendix({ descriptions }) {
  return (
    <div className='product__appendix'>
      <div className='product__description'>
        <h3 className='product__description__title'>Leírás</h3>
        {descriptions.map((description, index) => {
          return <p key={index}>{description}</p>;
        })}
      </div>
    </div>
  );
}

export default ProductAppendix;
