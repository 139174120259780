import Header from "./header";
import Hero from "./hero";
import NavBar from "./nav";
import Footer from "./footer";
import CookieConsent from "react-cookie-consent";

function MainPage(props) {
  return (
    <div
      className='main__content'
      style={{ backgroundImage: "url(/images/bg-pattern.jpg)" }}>
      <Header />
      <Hero />
      <NavBar />
      {props.children}
      <Footer />
      <CookieConsent
        location='bottom'
        buttonText='Elfogadom'
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}>
        Ez az oldal sütiket használ...
      </CookieConsent>
    </div>
  );
}

export default MainPage;
