import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from "./components/mainPage";
import Producers from "./components/producers";
import Producer from "./components/producer";
import DataGdpr from "./components/data-gdpr";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <div className='App'>
        <MainPage>
          <Routes>
            <Route index path='/' element={<Producers />} />
            <Route path='/action' element={<Action />} />
            <Route path='/download' element={<Download />} />
            <Route path='/connection' element={<Connections />} />
            <Route path='/details' element={<Details />} />
            <Route path='/gdpr' element={<DataGdpr />} />
            <Route path='/producer/:producer' element={<Producer />}></Route>
          </Routes>
        </MainPage>
      </div>
    </BrowserRouter>
  );
}

function Action() {
  return <h2>Actions</h2>;
}

function Download() {
  return <h2>Download</h2>;
}

function Connections() {
  return <h2>Connections</h2>;
}

function Details() {
  return <h2>Details</h2>;
}

export default App;
