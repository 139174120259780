import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className='footer'>
      <Link className='footer__gdpr' to='gdpr'>
        Adatvédelmi nyilatkozat
      </Link>
      <p className='footer__musician'>Zene: Mihovics Zoltán</p>
      <audio
        className='footer__autdio'
        controls
        src='music/Christmas2.mp3'></audio>
    </footer>
  );
}

export default Footer;
