import ProducerLogo from "./producer-logo";

function ProducerCaption({ producer }) {
  const { producerName, producerLink, producerDescription } = producer;
  return (
    <div className='producer__caption'>
      <ProducerLogo producerName={producerName} producerLink={producerLink} />
      <p className='producer__description'>{producerDescription}</p>
    </div>
  );
}

export default ProducerCaption;
